// 卫星影像或航摄影像
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                    prop: 'ZLMC',
                    label: '资料名称',
                    minWidth: 105,
                },
                // {
                //     prop: 'MC',
                //     label: '名称',
                //     minWidth: 105,
                // }, {
                //     prop: 'NF',
                //     label: '年份',
                //     sortable: 'custom'
                // },
                {
                    prop: 'LX',
                    label: '类型'
                }, {
                    prop: 'SYT',
                    label: '示意图',
                    showImg: true,
                }
            ],
        },
    },
    snSearch: {
        selectData: [{
            label: '类型',
            clearable: true,
            placeholder: "请选择类型",
            list: [{
                ID: '0',
                NAME: '卫星影像'
            }, {
                ID: '1',
                NAME: '航片'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'LX',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;